<template>
  <div id="home-page">
    <section class="hero-slider  swiper-container">
      <swiper class="swiper-wrapper" ref="bannerSwiper" :options="bannerSwiperOptions">
        <template v-for="(banner, index) in banners">
          <swiper-slide :key="index">
            <div class="hero" :style="`background: url(${banner.banner_url}); background-size: cover; background-position: center;`">
              <div class="container">
                <div class="row">
                  <div class="col-lg-8 offset-lg-2">
                    <div class="title">{{ banner.small_header }}</div>
                    <div class="main-title">{{ banner.big_header }}</div>
                    <a :href="banner.button_link" class="btn btn-orange" target="_blank">{{ banner.button_text }}</a>
                  </div>
                </div>
              </div>
            </div>
          </swiper-slide>
        </template>
        <div id="pagi-hero" class="cs swiper-pagination white-pagi" slot="pagination"></div>
      </swiper>
      <div id="arrow-hero" class="hero-arrow swiper-button-next"><span class="icon-arrow-right"></span></div>
      <div id="arrow-hero" class="hero-arrow swiper-button-prev"><span class="icon-arrow-left"></span></div>
    </section>

    <section class="about">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 col-sm-6">
            <img :src="imageAbout" class="img-fluid w-100">
          </div>
          <div class="col-lg-6 col-sm-6">
            <div class="desc-about">
              <h5>About Diplomacy Room</h5>
              <p v-html="about"></p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="services">
      <div class="container">
        <h5 class="text-center">Our Services</h5>

        <div class="row justify-content-center">
          <div class="col-lg-4 col-sm-6">
            <div class="box-white">
              <h4 class="service">Room</h4>
              <p>Explore topics that are based on issues reverberating in the news today.</p>
              <router-link to="/room" class="link-service">Learn more &nbsp;<span class="icon-chevron-right"></span></router-link>
            </div>
          </div>
          <div class="col-lg-4 col-sm-6">
            <div class="box-white">
              <h4 class="service">Simulation</h4>
              <p>United Nation (UN) simulations that present hypothetical scenarios based on real issues.</p>
              <router-link to="/simulation" class="link-service">Learn more &nbsp;<span class="icon-chevron-right"></span></router-link>
            </div>
          </div>
          <div class="col-lg-4 col-sm-6">
            <div class="box-white">
              <h4 class="service">Dashboard</h4>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</p>
              <router-link to="/dashboard" class="link-service">Learn more &nbsp;<span class="icon-chevron-right"></span></router-link>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="case-studies">
      <div class="container">
        <h5 class="text-center mb50">Latest Case Studies</h5>

        <swiper id="case-slider" class="swiper-container case-slider" ref="caseSwiper" :options="caseSwiperOptions">
          <swiper-slide v-for="(room, index) in rooms" :key="index">
            <div class="box-cs">
              <div class="img-room">
                <figure>
                  <router-link :to="`/room/${room.slug}`">
                    <img :src="room.banner_url" class="img-fluid w-100">
                  </router-link>
                </figure>
              </div>
              <div class="box-white">
                <h4 class="cs-head">{{ room.subject_topic }}</h4>
                <p>{{ room.case_overview | short_description(270) }}</p>
                <div class="date">Registration Date</div>
                <div class="date-info mb24">{{ room.registration_date }}</div>

                <div class="date">Simulation Date</div>
                <div class="date-info mb40">{{ room.simulation_date }}</div>

                <router-link :to="`/room/${room.slug}`" class="link-service">
                  View Details &nbsp; <span class="icon-chevron-right"></span>
                </router-link>
              </div>
            </div>
          </swiper-slide>
        </swiper>
        <div class="cs swiper-button-next"><span class="icon-arrow-right"></span></div>
        <div class="cs swiper-button-prev"><span class="icon-arrow-left"></span></div>

        <div class="for-link" style="margin-top: 50px">
          <router-link to="/simulation" class="link-underline">View All Cases</router-link>
        </div>
      </div>
    </section>

    <section class="agenda">
      <div class="container">
        <h5 class="text-center mb50">Upcoming Agenda</h5>

        <div class="row">
          <template v-for="(agenda, index) in agendas">
          <div class="col-lg-4 col-sm-6" :key="index">
            <div @click="detailAgenda(agenda.slug)" class="box-white mb30">
              <div class="inner-agenda">
                <div class="date-agenda">
                  {{ agenda.day }}
                  <span>{{ agenda.date }}</span>
                </div>
                <div class="agenda-text">
                  <h6>{{ agenda.title }}</h6>
                  {{ agenda.description | short_description(130) }}
                </div>
              </div>
            </div>
          </div>
          </template>
        </div>

        <div class="text-center mt-2">
          <router-link to="/agenda" class="link-underline">View All Agenda</router-link>
        </div>
      </div>

      <!-- popup detail agenda -->
      <div class="popup-agenda" :class="{ show: Object.keys(agenda).length > 0 }">
        <div class="popup-agenda-inner">
          <div class="agenda-close">
            <button class="btn btn-close for-agenda" @click="agenda = {}"></button>
          </div>
          <div class="mb24">
            <table class="table borderless" cellspacing="2">
              <tr>
                <td class="point">Title</td>
                <td>:</td>
                <td class="point-desc">{{ agenda.title }}</td>
              </tr>
              <tr>
                <td class="point">Start Date</td>
                <td>:</td>
                <td class="point-desc">{{ agenda.start_date }}</td>
              </tr>
              <tr>
                <td class="point">End Date</td>
                <td>:</td>
                <td class="point-desc">{{ agenda.end_date }}</td>
              </tr>
              <tr>
                <td class="point">Description</td>
                <td>:</td>
                <td class="point-desc" v-html="agenda.description"></td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </section>

    <section class="social-media">
      <div class="container">
        <h5 class="text-center mb50">Follow Us on Social Media</h5>
        <div class="social">
          <a :href="facebook" target="_blank" class="social-icon"><span class="icon-ico-facebook"></span></a>
          <a :href="twitter" target="_blank" class="social-icon"><span class="icon-ico-twitter"></span></a>
          <a :href="instagram" target="_blank" class="social-icon"><span class="icon-ico-instagram"></span></a>
          <a :href="youtube" target="_blank" class="social-icon"><span class="icon-ico-youtube"></span></a>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'Home',
  data() {
    return {
      bannerSwiperOptions: {
        slidesPerView: 1,
        spaceBetween: 0,
        loop: true,
        loopFillGroupWithBlank: true,
        navigation: {
          nextEl: "#arrow-hero.swiper-button-next",
          prevEl: "#arrow-hero.swiper-button-prev",
        },
        pagination: {
          el: "#pagi-hero.swiper-pagination",
          clickable: true,
        },
        mousewheel: false,
        keyboard: true,
      },
      caseSwiperOptions: {
        centerInsufficientSlides: true,
        slidesPerView: 3,
        spaceBetween: 30,
        navigation: {
          nextEl: ".cs.swiper-button-next",
          prevEl: ".cs.swiper-button-prev",
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        mousewheel: false,
        keyboard: true,
        breakpoints: {
          "320": {
            slidesPerView: 1,
            mousewheel: true,
          },
          "768": {
            slidesPerView: 2,
          },
          "1024": {
            slidesPerView: 3,
          },
        },
      },
      banners: [],
      about: null,
      imageAbout: null,
      rooms: [],
      agendas: [],
      agenda: {},
      facebook: '#',
      twitter: '#',
      instagram: '#',
      youtube: '#',
    }
  },
  mounted() {
    this.getBanner()
    this.getAbout()
    this.getRooms()
    this.getAgenda()
    this.getSocialMedia()
  },
  methods: {
    getBanner() {
      this.$axios.get('/cms/banner/slider')
        .then(response => {
          console.log(response)
          this.banners = response.data.data
        })
    },
    getAbout() {
      this.$axios.get('/cms/about')
        .then(response => {
          this.about = response.data.data.description
          this.imageAbout = response.data.data.image_url
        })
    },
    getRooms() {
      this.$axios.get('/homepage/latest_case_studies')
        .then(response => {
          console.log(response)
          this.rooms = response.data.data
        })
    },
    getAgenda() {
      this.$axios.get('/homepage/upcoming_agenda')
        .then(response => {
          this.agendas = response.data.data
        })
    },

    detailAgenda(slug) {
      this.$axios.get(`/master/agenda/${slug}`)
        .then(response => {
          this.agenda = response.data.data
        })
    },
    getSocialMedia() {
      this.$axios.get('/cms/social_media')
        .then(response => {
          this.facebook = response.data.data.facebook
          this.twitter = response.data.data.twitter
          this.instagram = response.data.data.instagram
          this.youtube = response.data.data.youtube
        })
    }
  }
}
</script>

<style scoped>
.box-white:hover {
  cursor: pointer;
}
.inner-agenda {
  height: 109px !important;
}
td {
  padding: 10px;
}
.point {
  width: 20%;
  font-size: 14px;
  color: #7B7B7B;
}
.point-desc {
  width: 80%;
  font-weight: 600;
  font-size: 14px;
  color: #434343;
}
</style>